import React, { useContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import algoliasearch from "algoliasearch/lite";
import useInfiniteScroll from "react-infinite-scroll-hook";
import get from "lodash/get";
import { Contentful_ContentTypeCollection } from "graphql-types";
import { GroupType } from "../filter";
import { FilterContext } from "../filter/context";
import { getFilters, algoliaFilters } from "../filter/utils";
import Loading from "./loading";
import Results from "./results";
import Error from "./error";

const client = algoliasearch(process.env.ALGOLIA_APP_ID ?? "", process.env.ALGOLIA_APP_SEARCH_KEY ?? "");
const index = client.initIndex(process.env.ALGOLIA_ARTICLES_INDEX ?? "");

function Collection() {
  const filterContext: GroupType[] = useContext(FilterContext);
  const [pageIndex, setPageIndex] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [infiniteLoading, setInfiniteLoading] = useState(true);
  const contentTypeData = useStaticQuery(filterQuery);

  /*Assign podcast channel as content types*/
  let podcastChannels = get(contentTypeData, "channel.podcastChannelCollection");
  const contentTypes: Contentful_ContentTypeCollection = get(contentTypeData, "filter.contentTypeCollection");

  /*merge podcast channels into the content type*/

  podcastChannels = {
    items: podcastChannels.items.map((x: any) =>
      Object.assign(x, {
        name: "Podcast",
        singularName: "Podcast",
      })
    ),
  };

  contentTypes.items.push(...podcastChannels.items);

  const [results, setResults] = useState<any>([]);

  function loadMore() {
    setInfiniteLoading(true);
    search();
  }

  const infiniteRef = useInfiniteScroll({
    loading: infiniteLoading,
    hasNextPage,
    onLoadMore: loadMore,
  });

  function search(reset = false) {
    let filters = algoliaFilters(filterContext);

    const contentTypeItems = filterContext?.find((x) => x.groupLabel === "Content Type")?.items;
    //only find content type items if there is no other dropdown selected
    const hasOnlyEventsSelected =
      filterContext?.filter((x) => x.groupLabel !== "Content Type" && x.items.find((x) => x?.selected)).length == 0 &&
      contentTypeItems?.filter((x) => x?.selected).length === 1 &&
      !!contentTypeItems?.find((x) => x?.selected === true && x.name === "Events");

    setHasError(false);

    if (reset) {
      setLoading(true);
      setInfiniteLoading(false);
      setPageIndex(0);
      setHasNextPage(false);
    }

    const facetFilters = [
      getFilters("contentType", filters),
      getFilters("researchAreasCollection", filters),
      getFilters("sectorsCollection", filters),
    ].filter((x) => x.length);

    index
      .search("", {
        facetFilters,
        hitsPerPage: 40,
        page: reset ? 0 : pageIndex,
      })
      .then(({ hits, nbPages, page }: { hits: any; nbPages: number; page: number }) => {
        /**
         * Only if only events are selected we will order by the sort order field - otherwise if user search for all fields
         * future events will always show up first as they have the eventDate as the sort field
         */
        if (reset) {
          const data = hasOnlyEventsSelected
            ? [...hits].sort((a, b) => new Date(b.sortDate).getTime() - new Date(a.sortDate).getTime())
            : [...hits];
          setResults(data);
        } else {
          const data = hasOnlyEventsSelected
            ? [...results, ...hits].sort((a, b) => new Date(b.sortDate).getTime() - new Date(a.sortDate).getTime())
            : [...results, ...hits];
          setResults(data);
        }

        setTimeout(() => {
          setLoading(false);
          setInfiniteLoading(false);
          setPageIndex(page + 1);
          setHasNextPage(nbPages - 1 > page);
        }, 500);
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
        setHasNextPage(false);
        setPageIndex(0);
      });
  }

  useEffect(() => {
    search(true);
  }, [, filterContext]);

  return (
    <>
      <Error hasError={hasError} />
      <Loading loading={loading} />
      <div ref={infiniteRef}>
        <Results loading={loading} hasError={hasError} results={results} contentTypes={contentTypes} />
        <Loading loading={infiniteLoading} max={4} />
      </div>
    </>
  );
}

export default Collection;

const filterQuery = graphql`
  query FilterQuery {
    channel: contentful {
      podcastChannelCollection {
        items {
          sys {
            id
            publishedVersion
          }
        }
      }
    }
    filter: contentful {
      contentTypeCollection(order: name_ASC) {
        items {
          sys {
            id
            publishedVersion
          }
          name
          singularName
        }
      }
    }
  }
`;
